import React from 'react';
import { Typography, Divider } from 'antd';
import FontSizeSetting from './components/FontSizeSetting';

export default function Settings() {
    return (
        <div className='full-width content-centered full-height'>
            <div className='theme-max-width full-width box-style'>
                <Typography.Title className='full-width pad-hori' level={3}>Settings</Typography.Title>
                <Divider />
                    <FontSizeSetting />
                <Divider />
            </div>
        </div>
    );
}