import { GeoJson, GeoJsonFeature } from "../types";
import * as projection from "@turf/projection";
import turfCenter from "@turf/center";

export const toWgs84 = (geoJson: GeoJson | GeoJsonFeature): GeoJson => {
    const cloned = JSON.parse(JSON.stringify(geoJson));
    return projection.toWgs84(cloned);
};

export const center = (geoJson: GeoJson): [number, number] => {
    const center = turfCenter(geoJson as any);
    return [center.geometry.coordinates[0], center.geometry.coordinates[1]];
}

export const getCurrentLocation = (): Promise<GeoJson> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve(
                    {
                        type: "FeatureCollection",
                        features: [{
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [position.coords.longitude, position.coords.latitude]
                            },
                            properties: {
                                name: "You are here"
                            }
                        }]
                    }
                );
            }, (error) => {
                reject(error);
            });
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    })
}