import dayjs from 'dayjs';
import { ulid } from 'ulidx';
import { SmartValueAttachment } from '../types';

export const encodeBase64 = (data: string) => {
  return btoa(data);
}

export const decodeBase64 = (data: string) => {
  return atob(data);
}

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
}

export const generateId = (): string => ulid();

export const convertBase64ToFile = async (base64Url: string, fileName: string): Promise<File> => {
  const res = await fetch(base64Url);
  const blob = await res.blob();
  return new File([blob], fileName, { type: blob.type });
}

export const generateFileName = (extension: string, name: string = dayjs().toISOString()) => `${name}.${extension}`;

export const convertBlobToBase64 = (blob: Blob, mimeType: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // const dataUrlPrefix = `data:${mimeType};base64,`;
      const base64WithDataUrlPrefix = reader.result;
      const base64 = (base64WithDataUrlPrefix as string); //.replace(dataUrlPrefix, '');
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getDateValue = (value: string | undefined) => {
  let val;
  switch (value) {
    case 'today':
      val = dayjs();
      break;
    case 'yesterday':
      val = dayjs().subtract(1, 'day');
      break;
    case 'tomorrow':
      val = dayjs().add(1, 'day');
      break;
    default:
      val = dayjs(value);
      break;
  }
  return val;
}

export const getTimeValue = (value: string | undefined) => {
  let val;
  switch (value) {
    case 'now':
      val = dayjs();
      break;
    default:
      val = dayjs(value);
      break;
  }
  return val;
}

export const parseValue = (type: string, value: any) => {
  switch (type) {
    case 'date':
      return getDateValue(value);
    case 'time':
      return getTimeValue(value);
    default:
      return value;
  }
}

export const getValueInPath = (valueObject: object, path: string) => {
  const paths = path.split('.');
  let currentObject = valueObject;
  let pathIndex = 0;
  while (pathIndex < paths.length - 1) {
    currentObject = valueObject[paths[pathIndex]];
    pathIndex++;
  }

  return currentObject[paths[pathIndex]];

}

const IMG_EXT = ['png', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'svg', 'webp', 'heic'];

export const removeUrlQueryParams = (url: string) => {
    const paths = url.split('?');
    return paths[0];
}

export const isImage = (fileName: string): boolean => {
    const ext = getFileExtension(removeUrlQueryParams(fileName));
    if (!ext) {
        return false;
    }
    return IMG_EXT.includes(ext.toLowerCase());
}

export const getFileFriendlyName = (fileName: string) => {
  return decodeURIComponent(removeUrlQueryParams(fileName).split('/').pop() || '');
}

export const groupByExt = (attachments: SmartValueAttachment[] | undefined) => {
    const groupedAttachments: { images: SmartValueAttachment[], other: SmartValueAttachment[] } = {
      images: [],
      other: []
    };
    attachments?.forEach((file) => {
        if(isImage(file.attachment)) {
            groupedAttachments.images.push(file);
        } else {
            groupedAttachments.other.push(file);
        }
    });
    return groupedAttachments;
}

export const download = (url: string, fileName: string) => {
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const capitalizeFirstLetter = (text: string) => {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
}