import { Divider, Space, Typography, Flex, Button } from 'antd';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import React, { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type PropsType = {
    title?: ReactNode,
    children?: ReactElement,
    showBackButton?: boolean
    handleCloseButton?: () => void
    className?: string
}

export default function ViewLayout({ title, children, showBackButton, handleCloseButton, className }: PropsType) {
    const navigate = useNavigate();

    const titleContent = title ? (
            <Typography.Title level={3} className='pad-all no-margin text-overflow-ellipse view-layout-title'>{title}</Typography.Title>
    ) : <></>;

    const backButton = showBackButton ? (
        <Flex className='margin' gap="small" wrap="wrap">
            <Button className='border-radius' icon={<LeftOutlined />} onClick={() => navigate(-1)} />
        </Flex>
    ) : <></>;

    const closeButton = handleCloseButton ? (
        <Button className='no-border' icon={<CloseOutlined />} onClick={handleCloseButton} />
    ) : <></>;

    const scrollViewClass = !title ? 'no-title' : '';

    return (
        <div className='full-width content-centered full-height'>
            <div className='theme-max-width full-width box-style full-height'>
                <Space.Compact direction='vertical' className={`full-width padding-top ${className}`}>
                    <div className='content-justified'>
                        {backButton}
                        {titleContent}
                        {closeButton}
                    </div>
                    <Divider className='no-margin pad-hori' />
                    <Space.Compact className={`full-width scroll-view pad-hori ${scrollViewClass}`}>
                        {children}
                    </Space.Compact>
                </Space.Compact>
            </div>
        </div>
    );
}