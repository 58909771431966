import React from 'react';
import { QrScanner, ViewLayout } from '../../../components';

type PropsType = {
    onQrRead: (data: string) => void
}

export default function ScanView({ onQrRead }: PropsType) {

    return (
        <ViewLayout title="Please scan the QR.">
            <div className='scan-view pad-all content-centered full-width'>
                <QrScanner onScan={onQrRead} />
            </div>
        </ViewLayout>
    )
}