import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Popover, TooltipProps } from 'antd';
import React, { ReactNode } from 'react';

type PropsType = {
    title?: ReactNode;
    content?: ReactNode;
    placement?: TooltipProps['placement'];
}

export default function HelpTip({ title, content, placement }: PropsType) {

    return (
        <Popover title={title} content={content} placement={placement}>
            <QuestionCircleTwoTone style={{ fontSize: 24, cursor: 'pointer' }} />
        </Popover>
    )
}