import axios from "axios";
import { ApiConfig } from "../configs/configs";

export const fetchText = async (url: string): Promise<string> => {
    const res = await fetch(url);
    if (!res.ok) {
        throw new Error(`${res.status}`);
    }
    return res.text();
}

export const fetchJson = async <T>(url: string, reCaptchaToken: string | null, headers?: any): Promise<T> => {
    const params: any = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            'x-recaptcha-token': reCaptchaToken
        },
        redirect: "follow"
    };
    if (headers) {
        params.headers = { ...params.headers, ...headers };
    }
    const res = await fetch(url, params);
    if (!res.ok) {
        throw new Error(`${res.status}`);
    }
    return res.json();
}

export const postApi = async <B, R>(url: string, body: B, reCaptchaToken: string): Promise<R> => {
    const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json",
            'x-recaptcha-token': reCaptchaToken
        },
        redirect: "follow",
        body: JSON.stringify(body)
    });
    if (!res.ok) {
        throw new Error(`${res.status}`);
    }
    return await res.json();
}

export const uploadApi = async (url: string, file: File, onUploadProgress: (progress: number) => void): Promise<void> => {
    axios.put(url, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
                onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        }
    });
}

export const getApiFullUrl = (path: string): string => `${ApiConfig.basePath}${path}`;