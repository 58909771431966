import { Space, Col, Typography } from 'antd';
import packageJson from '../../../package.json';

export default function About() {

    return (
        <div className='full-width full-height content-centered scrollable-y'>
            <div className='theme-max-width full-width box-style full-height'>
                <Space.Compact direction='vertical' className='full-width full-height pad-hori'>
                    <Typography className="about-title">Revolutionising site Access, Location, Data, and Organisation in One Powerful App</Typography>

                    <div className='about-section'>
                        <Typography className="about-sub-title">The ALDO Advantage</Typography>
                        <Col>
                            <Typography className="text-content">
                                Introducing ALDO, epar’s innovative app designed to revolutionise site safety access while exemplifying and showcasing sustainability. ALDO streamlines site access procedures, ensuring swift and secure entry for staff, contractors, visitors, and volunteers. ALDO also illuminates the sustainability journey of any business, large or small. Through vivid imagery and informative content, ALDO brings your sustainability story to life capturing the attention of stakeholders, customers, and the wider community.
                            </Typography>
                        </Col>
                    </div>
                    <div className='about-section'>
                        <Typography className="about-sub-title">Inspired by Aldo Leopold</Typography>
                        <p className='text-content'><img className='about-image' src="/Aldo-Leopold.png" alt='' />
                            ALDO is inspired by Aldo Leopold, a pioneer in systems thinking. Leopold's approach to understanding interconnectedness and the importance of holistic management is mirrored in our app.
                            We aim to bring those principles into the digital age, providing you with a tool that aligns with the wisdom of one of the greatest systems thinkers of our time. ALDO is not just an app; it's a game-changer for businesses seeking to harness the power of location data effectively. By drawing inspiration from Aldo Leopold's vision of balance and harmony in ecosystems, we've created a tool that brings order to the often-complex world of people and data management.
                        </p>
                    </div>
                    <div className="about-footer">
                        <p>Version : {packageJson.version}</p>
                    </div>
                </Space.Compact>
            </div>
        </div>
    );
}