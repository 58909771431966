export const LOGGED_USER = 'LOGGED_USER';

export const ROUTES = {
    INBOX: '/inbox',
    ARTIFACT: '/artifact',
    SETTINGS: '/settings',
    HOME:'/home',
    ABOUT:'/about',
}

export const SUBSMISSION_STATUS = {
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed'
}

export const SUBSMISSION_TYPES = {
    SMART_VALUE: 'SmartValue'
}

export const USER_ATTRIBUTES = [
    "email",
    "firstName",
    "lastName",
    "phoneNumber",
    "mobilePhone",
    "homePhone",
    "address",
    "name"
];

export const FORM_TYPES = {
    ASSET_CHECKOUT : "AssetCheckout",
    ACKNOWLEDGEMNET : "Achnowledgement"
}

export const ERROR_MESSAGE = {
    "Session already started": { icon: 0, description: "Please complete the existing session before initiating a new one." },
    "Session not started yet": { icon: 1, description: "Please start a session before completing it." }
}