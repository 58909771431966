import { Flex, Layout, Typography, Space } from 'antd';
import packageJson from '../../../package.json';
const { Content } = Layout;

export default function SplashView() {
    return (
        <Layout className='full-width full-height white-bg'>
            <Content className='splash-content-height'>
                <div className='full-width content-centered full-height'>
                    <div className='theme-max-width full-width box-style full-height'>
                        <Space.Compact direction='vertical' className='full-width full-height content-centered'>
                            <Flex className='content-centered flex-column'>
                                <img className='splash-image' src='/aldo-splash.png' alt='' />
                                <Typography.Text className='splash-version'>Version : {packageJson.version}</Typography.Text>
                            </Flex>
                        </Space.Compact>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}