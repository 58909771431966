import React, { useContext } from 'react';
import FormPane from './FormPane';
import SmartValueDetails from './SmartValueDetails';
import { authContext } from '../../../context/auth';
import { SUBSMISSION_TYPES } from '../../../constants';

export default function ArtifactDetailView() {

    const { authState } = useContext(authContext)
    const { qrData } = authState;

    const getBody = () => {
        if(qrData && qrData.submissionType === SUBSMISSION_TYPES.SMART_VALUE)
            return <SmartValueDetails />

        return <FormPane />

    }

    return (
        getBody()
    )
}
