import { HeartTwoTone } from '@ant-design/icons';
import { Button, Space, Typography, notification } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ACTIONS, authContext } from '../../context/auth';

const NOTI_KEY = 'app-install-req-noti';

export default function AppInstallRequester() {
    const [deferredPrompt, setDiferredPrompt] = useState<Event | null>(null);
    const [notiApi, contextHolder] = notification.useNotification();
    const { dispatch, authState } = useContext(authContext);
    const { appInstallRequested } = authState;

    const onLaterClick = useCallback(() => {
        notiApi.destroy(NOTI_KEY);
    }, [notiApi]);

    const onInstallClick = useCallback(() => {
        try {
            if (deferredPrompt) {
                (deferredPrompt as any).prompt();
            }
        } catch (e) {
            console.log(e);
        } finally {
            onLaterClick();
        }
    }, [deferredPrompt, onLaterClick]);

    const requestInstall = useCallback(() => {
        notiApi.open({
            key: NOTI_KEY,
            message: <b>Install ALDO</b>,
            description: <Typography.Text>Install <b>ALDO</b> on your home screen for quick and easy access when you are on the go.</Typography.Text>,
            icon: <HeartTwoTone twoToneColor="#eb2f96" />,
            duration: 10,
            closeIcon: null,
            btn: <Space>
                <Button onClick={onLaterClick}>Later</Button>
                <Button onClick={onInstallClick} type='primary'>Install</Button>
            </Space>
        });
    }, [notiApi, onInstallClick, onLaterClick]);

    useEffect(() => {
        if (deferredPrompt && !appInstallRequested) {
            dispatch({ type: ACTIONS.APP_INSTALL_REQUESTED, payload: true });
            requestInstall();
        }
    }, [deferredPrompt, requestInstall, appInstallRequested, dispatch]);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            setDiferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    return (
        <>
            {contextHolder}
        </>
    );
}