import React, { useEffect, useState } from 'react';
import { Typography, Button, Tooltip } from 'antd';

export default function FontSizeSetting() {
    const [fontType, setFontType] = useState<string>('small');

    useEffect(() => {
        setFontType(localStorage.getItem('fontType') ?? 'small')
    }, [])

    const fontSizes = [
        {
            fontType: 'small',
            fontSize: '14px',
            title: '[Small] Font size: 14px'
        },
        {
            fontType: 'middle',
            fontSize: '16px',
            title: '[Medium] Font size: 16px'
        },
        {
            fontType: 'large',
            fontSize: '18px',
            title: '[Large] Font size: 18px'
        },
    ];

    const sizeMapping = {
        small: 'small',
        middle: 'middle',
        large: 'large',
    };

    const changeFontType = (fontType: string) => {
        localStorage.setItem('fontType', fontType);
        setFontType(fontType);
    }

    return (
        <div>
            <Typography.Title className='full-width pad-hori' level={5}>Change Font Size :</Typography.Title>
            <div className='font-size-adjust-container'>
                {fontSizes.map((fontSize, index) => (
                    <Tooltip key={index} title={fontSize.title}>
                        <Button
                            shape='circle'
                            type={fontType === fontSize.fontType ? 'primary' : 'default'}
                            size={sizeMapping[fontSize.fontType]}
                            className={`font-size-adjust-button ${fontSize.fontType}`}
                            onClick={() => { changeFontType(fontSize.fontType) }}
                        >A</Button>
                    </Tooltip>
                ))}
            </div>
        </div>
    );
}