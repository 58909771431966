import { useCallback, useEffect, useState } from 'react';
import { DescriptionsProps, Divider, Flex, Image, Typography, message, Space, Button } from 'antd';
import { useParams } from 'react-router-dom';
import ViewLayout from '../../../components/ViewLayout/ViewLayout'
import { getSubmissionDetails } from '../actions';
import { Loading } from '../../../components';
import { isImage, getFileFriendlyName } from '../../../utils';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadFile } from '../actions';
import { toDateString, toTimeString } from '../../../utils/datetime';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function InboxViewDetails() {

    const { submissionId } = useParams();
    const [details, setDetails] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const loadDetails = useCallback(async (submissionId: string) => {
        try {
            if (!executeRecaptcha) {
                return;
            }
            setLoading(true);
            const reCaptchaToken = await executeRecaptcha('load_submission_details');
            const result = await getSubmissionDetails(submissionId, reCaptchaToken);
            setDetails(result);
        } catch (e) {
            message.error('Error in loading details');
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [executeRecaptcha])

    useEffect(() => {
        if (submissionId)
            loadDetails(submissionId);
    }, [loadDetails, submissionId]);

    const downloadAttachment = useCallback(async (url: string, fileName: string) => {
        if (!executeRecaptcha) {
            return;
        }
        const reCaptchaToken = await executeRecaptcha('download_submission_attachment');
        await downloadFile(url, fileName, reCaptchaToken);
    }, [executeRecaptcha]);

    const getImagePreview = (urls: any) => {
        if (!urls) return <></>
        return (
            <>
                <div className='pad-vertical image-group'>
                    <Image.PreviewGroup>
                        {(urls as string[]).map((url, idx) => <Image className='image' key={`img-${idx}`} src={url} />)}
                    </Image.PreviewGroup>
                </div>
            </>
        );
    }

    const getUploadPreview = (urls: any) => {
        if (!urls) return <></>
        const urlss = urls as string[];
        const images: string[] = [];
        const files: string[] = [];
        urlss.forEach(url => {
            if (isImage(url)) {
                images.push(url);
            } else {
                files.push(url)
            }
        });

        return <div className='pad-vertical'>
            <Space direction='vertical'>
                {getImagePreview(images)}
                {(files).map((url, idx) => {
                    const fileName = getFileFriendlyName(url) ?? '';
                    return (
                        <Button type='link' key={`attc-${idx}`} className='border-radius' onClick={() => downloadAttachment(url, fileName)} icon={<DownloadOutlined />}>{fileName}</Button>
                    )
                })}
            </Space>
        </div>
    }

    const getPhoneNumberPreview = (values: any) => {
        if (!values) return <></>
        return <div>{values.dialCode} {values.number}</div>
    }

    const getDatePreview = (values: any) => {
        if (!values) return <></>
        return <div>{toDateString(values)}</div>
    }

    const getTimePreview = (values: any) => {
        if (!values) return <></>
        return <div>{toTimeString(values)}</div>
    }

    const getChildComponent = (field: any, value: any) => {
        switch (field.type) {
            case 'phone':
                return getPhoneNumberPreview(value)
            case 'date':
                return getDatePreview(value)
            case 'time':
                return getTimePreview(value)
            case 'signature':
            case 'camera':
                return getImagePreview(value)
            case 'upload':
                return getUploadPreview(value)
            default:
                return value;
        }
    }

    const getItems = (): DescriptionsProps['items'] => {
        const items = details?.schema.fields.map(((field: any) => {
            const children = getChildComponent(field, details.submission.formValues[field.name]);
            return children ? {
                key: field.name,
                label: field.label,
                children: children
            } : null;
        })) ?? [];
        return items.filter((item: any) => !!item) as DescriptionsProps['items'];
    }

    if (isLoading) return <Loading />

    return (
        <ViewLayout title={details?.submission.sessionFormName ?? details?.submission.formTitle} showBackButton={true} className='content-height'>
            <div className='details'>
                {getItems()?.map(item => (
                    <Flex key={item.key} className='row' vertical>
                        <Typography.Text className='label'>{item.label}</Typography.Text>
                        <Typography.Text className='value'>{item.children}</Typography.Text>
                        <Divider className='divider' />
                    </Flex>
                ))}
            </div>
        </ViewLayout>
    )
}

export default InboxViewDetails
