import React, {useState} from 'react';
import { TabViewLayout } from '../../components';
import InboxTabBody from './components/InboxTabBody';
import { SUBSMISSION_STATUS } from '../../constants';
import { TabsProps } from 'antd';
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

export default function Inbox() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

    const tabItems: TabsProps['items'] = [
        {
            key: 'active',
            label: <span><ClockCircleOutlined /> Active </span>,
            children: <InboxTabBody status={SUBSMISSION_STATUS.IN_PROGRESS} searchPhrase={searchPhrase} refreshTrigger={refreshTrigger} />
        },
        {
            key: 'history',
            label: <span><CalendarOutlined /> History</span>,
            children: <InboxTabBody status={SUBSMISSION_STATUS.COMPLETED} searchPhrase={searchPhrase} refreshTrigger={refreshTrigger} />
        }
    ];

    const activeTab = () => {
        const tab = searchParams.get('tab')
        if(tabItems){
            if (tab && tabItems?.find(item => item.key === tab)) return tab
            return tabItems[0].key
        }
    }
    const handleTabChange = (key: string) => setSearchParams({ tab: key })

    const onSearch = (searchText: string) => {
        setSearchPhrase(searchText);
    }

    const triggerInboxRefresh = () => setRefreshTrigger(prev => !prev);

    return (
        <TabViewLayout title="Inbox" tabs={tabItems} defaultActiveTabKey='active' activeKey={activeTab()} onTabChange={handleTabChange}
            searchPlaceHolder='Search Inbox...'
            onRefreshButtonClick={triggerInboxRefresh}
            onSearch={onSearch} />
    );
}