import React from 'react';
import { Field, getRules } from './DynamicFormItems';
import { Form, Input, Select } from 'antd';
import { getDialCode, getDialCodes, getDefualtDialCode } from '../../utils/country-codes';

type PropsType = {
    field: Field,
    keyId: string
}

const dailCodes = getDialCodes();
export const isValidPhoneNumber =
    (phoneNumber: string) => (/^[+]?[0-9]{3}?[0-9]{3}[0-9]{4,6}$/im).test(phoneNumber);


export default function PhoneFormItem({ field, keyId }: PropsType) {

    const { name, label, placeholder, required } = field;
    let _rules = getRules(field);

    const selectFilterOption = (input: string, option?: { label: string; title: string, value: string }) =>
        (option?.title ?? '').toLowerCase().includes(input.toLowerCase());

    const validator = ({ getFieldValue }) => ({
        validator(_, value) {
            const countryCode = getFieldValue([name, 'countryCode']);
            if (!countryCode && !value) return Promise.resolve();
            const countryDailCode = getDialCode(countryCode);
            if (countryCode && countryDailCode && value && isValidPhoneNumber(`${countryDailCode}${value}`)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("Please enter a valid phone number"));
        },
    })

    const phoneNumberPrefix = (
        <Form.Item name={[name, "countryCode"]} key={`${keyId}-country-code`} required={required} noStyle initialValue={getDefualtDialCode()}>
            <Select
                size='large'
                className="contact-number-dial-code"
                popupMatchSelectWidth={false}
                optionLabelProp="title"
                placeholder="+61"
                showSearch
                filterOption={selectFilterOption}>
                {dailCodes.map((code) => (
                    <Select.Option key={code.code} value={code.code} title={code.dialCode}>
                        {code.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
    return (<Form.Item
        key={`${keyId}-contact-number`}
        name={[name, "number"]}
        className="contact-number-field"
        dependencies={[[name, "countryCode"]]}
        label={label}
        tooltip={placeholder}
        rules={[..._rules, validator]}
    >
        <Input size='large' addonBefore={phoneNumberPrefix} placeholder={placeholder} />
    </Form.Item>);
}