import React from 'react'
import { ConfigProvider } from 'antd'

function ThemeProvider({ children }: { children: React.ReactNode }) {
    return (
        <ConfigProvider
        theme={{
            token: {
              colorPrimary: '#1D39C4',
            },
          }}
        >
            {children}
        </ConfigProvider>
    )
}

export default ThemeProvider