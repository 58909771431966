import { ReactElement, useContext, useEffect, useState } from "react";
import { ROUTES } from "../../../constants";
import { ACTIONS, authContext } from "../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Layout } from 'antd';
import { InboxOutlined, QrcodeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MenuItem } from '../../../types';
import { SUBSMISSION_TYPES } from "../../../constants";

const { Header } = Layout;

function getMenuItem(key: string, label: string, icon?: ReactElement): MenuItem {
    return { key, label, icon, };
}

const items: MenuItem[] = [
    getMenuItem(ROUTES.ABOUT, 'About', <InfoCircleOutlined className='icon' />),
    getMenuItem(ROUTES.ARTIFACT, 'Scan', <QrcodeOutlined className='icon' />),
    getMenuItem(ROUTES.INBOX, 'Inbox', <InboxOutlined className='icon' />),
];

function AppHeader() {
    const { dispatch, authState } = useContext(authContext);
    
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [selectedMenu, setSelectedMenu] = useState<string>(ROUTES.ARTIFACT);

    const onMenuSelect = (key: string) => {
        if (key === ROUTES.ARTIFACT) {
            dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: undefined });
        }
        navigate(key);
    }

    const headerBehavior = authState.qrData && authState.qrData.submissionType !== SUBSMISSION_TYPES.SMART_VALUE ? 'hide-header' : 'show-header';  
    
    useEffect(() => {
        if (pathname !== selectedMenu) {
            setSelectedMenu(pathname);
        }
    }, [pathname, selectedMenu]);

    return (
        <Header className={`flex-header pad-hori content-centered ${pathname === ROUTES.HOME ? 'glossy' : 'plain'} ${headerBehavior}`} >
            <Flex justify='space-between' className='theme-max-width full-width logo-wrapper'>
                {
                    pathname === ROUTES.HOME
                        ?
                        <img src='/logos/logo-light.svg' className='header-logo' alt='light logo' height={40} onClick={() => onMenuSelect(ROUTES.HOME)} />
                        :
                        <img src='/logos/logo-dark.svg' className='header-logo' alt='dark logo' height={40} onClick={() => onMenuSelect(ROUTES.HOME)} />
                }
                <div className="header-menu">
                    {
                        items.map((item) => (
                            <Button key={item.key} type="text" icon={item.icon} onClick={() => onMenuSelect(item.key)} />
                        ))
                    }
                </div>
            </Flex>
        </Header>
    )
}

export default AppHeader