import React, { useCallback, useContext, useEffect, useState } from 'react';
import ScanView from './components/Scan';
import ArtificatDetailView from './components/ArtificatDetail';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { ACTIONS, authContext } from '../../context/auth';
import { Loading } from '../../components';
import { decodeBase64 } from '../../utils';

enum UiState {
    ScanQrCode,
    CheckOutAsset
}

export default function ArtifactView() {
    const [uiState, setUiState] = useState<UiState>(UiState.ScanQrCode);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { dispatch, authState } = useContext(authContext);
    const { qrData } = authState;

    const location = useLocation();

    const readQrCode = useCallback((text: string) => {
        try {
            const url = new URL(text);
            const qrDataParam = url.searchParams.get('qr-data') as string;
            if (!qrDataParam) {
                return;
            }
            const data = decodeURIComponent(decodeBase64(qrDataParam));
            dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: JSON.parse(data) });
        } catch (e) {
            console.log(e);
            message.error('Not a valid ALDO QR code. Please scan a valid ALDO QR code.', 10);
        }
    }, [dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => setLoading(false), 1000);
        return () => clearTimeout(timeout);
    })

    useEffect(() => {
        readQrCode(window.location.href);
    }, [location, readQrCode]);

    useEffect(() => {
        setUiState(qrData ? UiState.CheckOutAsset : UiState.ScanQrCode);
    }, [qrData]);

    const onQrRead = (text: string) => readQrCode(text);

    const content = isLoading ? <Loading /> :
        uiState === UiState.ScanQrCode ? <ScanView onQrRead={onQrRead} /> :
            <ArtificatDetailView />;

    return content;
}