import { LOGGED_USER, USER_ATTRIBUTES } from "../constants";
import { ACTIONS, DispatchType } from "../context/auth";
import { User } from "../types";

export const getLoggedUser = (): User | null => {
    const loggedUserString = window.localStorage.getItem(LOGGED_USER);
    if (!loggedUserString) {
        return null;
    }
    const loggedUser = JSON.parse(loggedUserString) as User;
    loggedUser.userId = loggedUser.userId.trim();
    return loggedUser;
}

export const saveLoggedUser = (user: User, dispatch: DispatchType) => {
    user.userId = user.userId.trim();
    window.localStorage.setItem(LOGGED_USER, JSON.stringify(user));
    dispatch({ type: ACTIONS.USER_SIGNED_IN, payload: user });
}

export const saveLoggedUserFromFormValues = (userId: string, formValues: any, canSaveToStore: boolean, dispatch: DispatchType) => {
    let user: User = { userId };
    if (canSaveToStore) {
        user = { ...(getLoggedUser() ?? user), userId };
        for (const attr of USER_ATTRIBUTES) {
            if (formValues[attr] !== undefined) {
                user[attr] = formValues[attr];
            }
        }
    }
    saveLoggedUser(user, dispatch);
}