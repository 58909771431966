import { Button, Checkbox, Form, message, Space, Flex, Typography, Tooltip } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, authContext } from '../../../context/auth';
import { DrawerRender, DynamicForm, Field, FormSchema, HelpTip, Loading, ViewLayout } from '../../../components';
import { AldoErrorCode, generateId, getValueInPath, parseValue, saveLoggedUserFromFormValues } from '../../../utils';
import { getSchema, saveCheckoutDetails, uploadFiles } from '../actions';
import { getDialCode } from '../../../utils/country-codes';
import FormSubmissionProgress from './FormSubmissionProgress';
import { ROUTES, ERROR_MESSAGE } from '../../../constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const FORM_SUBMIT = 'form-submit';

export default function FormPane() {
    const navigate = useNavigate();
    const { dispatch, authState } = useContext(authContext);
    const { qrData, authUser } = authState;
    const [formSchema, setFormScheam] = useState<FormSchema>();
    const [formInitialValues, setFormInitialValues] = useState<object>({});
    const [isLoading, setLoading] = useState<boolean>(true);
    const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [filesUploadProgress, setFilesUploadProgress] = useState<number>(0);
    const [totalUploadingFiles, setTotalUploadingFiles] = useState<number>(0);
    const [showAgreementDrawer, setShowAgreementDrawer] = useState<boolean>(false);
    const [canSaveUserDataForLater, setSaveUserDataForLater] = useState<boolean>(true);
    const [agreementUrl, setAgreementUrl] = useState<string>("");
    const [errorCode, setErrorCode] = useState<string>("");
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleAgreementClick = (e: MouseEvent, agreementLink: string) => {
        e.preventDefault()
        setAgreementUrl(agreementLink)
        setShowAgreementDrawer(true)
    }

    useEffect(() => {
        const agreement = document.querySelector("a#content") as HTMLElement
        const agreementLink = agreement?.getAttribute("data-url")
        if (agreement && agreementLink) agreement.onclick = (e) => handleAgreementClick(e, agreementLink)
    }, [formSchema?.fields, showAgreementDrawer])


    const getFilesUploadProgress = (): number => {
        if (totalUploadingFiles === 0) return 0;
        return Math.round(filesUploadProgress / totalUploadingFiles);
    };

    const populateProgressItems = (values: any) => {
        if (!formSchema) {
            throw new Error('Form schema is empty');
        }
        const uploadingFields = formSchema.fields.filter(f => f.uploadOnSubmit);
        let uploadingFileCount = 0;
        uploadingFields.forEach(field => {
            const files = values[field.name] ?? [];
            uploadingFileCount += files.length;
        });
        setTotalUploadingFiles(uploadingFileCount);
    }

    const onFileUploadStatusChange = (progress: number) => {
        setFilesUploadProgress(current => current + progress);
    }

    const getValue = useCallback((localData: any, type: string, value: any): any => {
        let val;
        if (value?.startsWith(':')) {
            val = getValueInPath(localData, value.substring(1))
        }
        return parseValue(type, val);
    }, []);

    const loadInitialValues = useCallback((schema: FormSchema) => {
        const initialValues = {};
        const localData = { ...(authUser ?? {}), ...(qrData?.extra ?? {}) };
        schema?.fields.forEach((field) => {
            initialValues[field.name] = getValue(localData, field.type, field.initialValue);
        });
        setFormInitialValues(initialValues);
    }, [getValue, authUser, qrData]);


    const loadFormSchema = useCallback(async () => {
        try {
            setErrorCode("");
            setLoading(true);
            if (!qrData?.formSchemaUrl || !executeRecaptcha || authUser === undefined) return;
            const schema = await getSchema(qrData.formSchemaUrl,
                qrData.sessionId,
                authUser?.userId,
                qrData.action,
                qrData?.extra?.assetId ?? undefined,
                async () => await executeRecaptcha('load_form_schema'));
            setFormScheam(schema);
            loadInitialValues(schema);
        } catch (e: any) {
            console.log(e);
            if (e?.code === AldoErrorCode.InvalidFormRequest) {
                setErrorCode(e?.message);
            }
            message.error(
                e?.code === AldoErrorCode.InvalidFormRequest ? e.message :
                    'Error in loading form. Please try again.')
        } finally {
            setLoading(false);
        }
    }, [qrData, loadInitialValues, executeRecaptcha, authUser]);

    useEffect(() => {
        loadFormSchema();
    }, [loadFormSchema]);

    const uploadFieldFiles = async (submissionId: string, field: Field, values: any): Promise<any> => {
        if (!field.uploadOnSubmit || !values[field.name]) {
            return values;
        }
        const keys = await uploadFiles(submissionId, field.name, values[field.name], async () => await executeRecaptcha!('upload_attachment'), onFileUploadStatusChange);
        values[field.name] = keys;
        return values;
    }

    const preparePhoneNumbers = (field: Field, values: any): Promise<any> => {
        if (field.type !== 'phone' || !values[field.name]) {
            return values;
        }

        values[field.name] = {
            ...values[field.name],
            dialCode: getDialCode(values[field.name]['countryCode'])
        };
        return values;
    }

    const preSubmit = async (submissionId: string, values: any): Promise<any> => {
        if (!formSchema) {
            throw new Error('Form schema is empty');
        }
        for (const field of formSchema.fields) {
            values = preparePhoneNumbers(field, values);
            values = await uploadFieldFiles(submissionId, field, values);
        }
        return values;
    };

    const canSubmit = () => formSchema?.schemaType === FORM_SUBMIT;

    const onSubmit = async (values: any) => {
        try {
            if (!canSubmit() || !executeRecaptcha) {
                return;
            }
            populateProgressItems(values);
            setFormSubmitting(true);
            const submissionId = generateId();
            await preSubmit(submissionId, values);
            const userId = values[formSchema!.userIdFieldName];
            const reCaptchaToken = await executeRecaptcha('submit_form');
            await saveCheckoutDetails(formSchema!.submissionType, {
                submissionId: submissionId,
                userId: userId,
                formTitle: qrData.sessionFormName ?? formSchema!.title,
                orgId: qrData.orgId,
                formValues: values,
                formUrl: qrData.formSchemaUrl,
                formVersion: formSchema!.version,
                formId: formSchema!.id,
                sessionId: qrData.sessionId,
                sessionFormName: qrData.sessionFormName,
                action: qrData.action,
                ...(qrData.extra ?? {})
            }, reCaptchaToken);
            message.destroy();
            message.success('Submitted successfully.');
            form.resetFields();
            dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: undefined });
            saveLoggedUserFromFormValues(userId, values, canSaveUserDataForLater, dispatch);
            navigate(ROUTES.HOME);
        } catch (e) {
            console.log(e);
            message.destroy();
            message.error('Error in submitting. Please try again.');
        } finally {
            setFormSubmitting(false);
        }
    };

    const onRescanRequest = () => dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: undefined });

    const onGotoInboxClick = () => navigate('/inbox');

    const reScanButton = <Button disabled={isFormSubmitting} className='full-width button-radius' htmlType="button" size="large" onClick={onRescanRequest}>
        Re-Scan the QR code
    </Button>;

    const submitButton = canSubmit() ? <Button disabled={isFormSubmitting} type='primary' size="large" className='full-width button-radius' htmlType="submit">
        Confirm
    </Button> : <></>

    const saveDataConsent = canSubmit() ?
        (<Space.Compact className='full-width'>
            <Checkbox checked={canSaveUserDataForLater}
                onChange={() => setSaveUserDataForLater(!canSaveUserDataForLater)}>
                Save details for later auto filling</Checkbox>
            <HelpTip
                title='Details Auto Filling'
                content={
                    <div style={{ width: 300 }}>
                        <p>By checking <b>Save details for later auto filling</b> following
                            details will be stored in your device cache and the forms require those details will be auto filled.</p>
                        <ul>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Email</li>
                            <li>Mobile Phone Number</li>
                            <li>Home Phone Number</li>
                            <li>Address</li>
                        </ul>
                    </div>
                }
            />
        </Space.Compact>) : <></>

    const agreementDrawer = showAgreementDrawer ? <DrawerRender title="Agreement" open={showAgreementDrawer} contentUrl={agreementUrl} onClose={() => setShowAgreementDrawer(false)} /> : <></>

    if (isLoading) return <Loading />;

    if (isFormSubmitting) return <FormSubmissionProgress progress={getFilesUploadProgress()} />;

    if (!formSchema) {
        return (
            <ViewLayout title="" handleCloseButton={onRescanRequest} className='form-content-height'>
                <Space.Compact className='flex full-width error-section'>
                    <Flex className='error-box border-radius margin-top'>
                        <div className='flex error-head'>
                            <img width={32} height={32} src={`/error-icons/${ERROR_MESSAGE[errorCode] ? ERROR_MESSAGE[errorCode].icon : '2'}.gif`} alt='' />
                            <Typography.Title level={5} className='error-title'>
                                {ERROR_MESSAGE[errorCode] ? errorCode : "Invalid QR code"}
                            </Typography.Title>
                        </div>
                        <Typography.Text className='error-text'>{ERROR_MESSAGE[errorCode] ? ERROR_MESSAGE[errorCode].description : "The scanned QR code is not recognized as a valid QR code."}</Typography.Text>
                    </Flex>
                    <div className='full-width pad-top checkout-form'>
                        <Space className='content-centered' direction='vertical'>
                            <Button type='primary' className='full-width border-radius' htmlType="submit" size='large' onClick={onGotoInboxClick}>
                                Goto Inbox
                            </Button>
                            {reScanButton}
                        </Space>
                    </div>
                </Space.Compact>
            </ViewLayout>
        );
    }

    const title = (
        <Tooltip placement="bottomLeft" title={formSchema.title} arrow>
            <span className='form-title'>{formSchema.title}</span>
        </Tooltip>
    );

    return (
        <ViewLayout title={title} handleCloseButton={onRescanRequest} className='form-content-height'>
            <div className='full-width pad-top margin-top form-wrappper'>
                {agreementDrawer}
                <Form
                    layout='vertical'
                    className='full-width checkout-form'
                    onFinish={onSubmit}
                    form={form}
                    scrollToFirstError
                    initialValues={formInitialValues}
                >
                    <DynamicForm fields={formSchema.fields} />
                    <Form.Item >
                        <Space className='content-centered' direction='vertical' size="middle">
                            {saveDataConsent}
                            {submitButton}
                            {reScanButton}
                        </Space>
                    </Form.Item>
                    <Form.Item />
                </Form>
            </div>
        </ViewLayout>
    );
}