import React from "react";
import QrReader from 'react-qr-scanner';

type PropsType = {
    onScan: (text: string) => void
}

export default function QrScanner({ onScan }: PropsType) {
    const onScannerRead = (data?: { text: string }) => {
        if (data?.text) {
            onScan(data.text);
        }
    }

    return (
        <QrReader
            delay={1000}
            style={{
                width: "100%",
                height: "100%"
            }}
            constraints={{ video: { facingMode: "environment" } }}
            facingMode={"environment"}
            onScan={onScannerRead}
            onError={(e: any) => console.log(e)}
        />
    );
}