import React, { useCallback, useEffect, useRef } from 'react';
import ArcGISMap from "@arcgis/core/Map.js";
import MapView from "@arcgis/core/views/MapView.js";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer.js";
import LabelClass from "@arcgis/core/layers/support/LabelClass.js";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer.js";
import { GeoJson, GeoJsonFeature } from '../../types';
import { toWgs84, center, getCurrentLocation } from '../../utils';

export type BaseMapType = "satellite" | "hybrid" | "terrain" | "oceans" | "osm" | "dark-gray-vector" | "gray-vector" | "streets-vector" | "topo-vector" | "streets-night-vector" | "streets-relief-vector" | "streets-navigation-vector";

type PropTypes = {
    width?: string,
    height?: string,
    basemap?: BaseMapType,
    zoom?: number,
    geoJson: GeoJson | GeoJsonFeature
    iconUrl: string
}

export default function GeoJsonMapView({
    width = "100%",
    height = "400px",
    basemap = "topo-vector",
    zoom = 12,
    geoJson,
    iconUrl
}: PropTypes) {

    const mapRef = useRef<HTMLDivElement>(null);

    const renderGeoJsonFeatures = useCallback((arcgisMap: ArcGISMap, geojson: GeoJson, logoUrl: string) => {
        try {
            const blob = new Blob([JSON.stringify(geojson)], {
                type: "application/json"
            });
            const geoJsonUrl = URL.createObjectURL(blob);
            const featureLayer = new GeoJSONLayer({ url: geoJsonUrl });
            const labelClass = new LabelClass({
                labelExpressionInfo: { expression: "$feature.name" },
                labelPlacement: "above-center",
                symbol: {
                    type: "text",
                    color: "blue",
                    haloSize: 0.2,
                    haloColor: "white"
                }
            });
            featureLayer.labelingInfo = [labelClass];
            const pointSymbol = new SimpleRenderer({
                symbol: new PictureMarkerSymbol({
                    url: logoUrl,
                    width: "22px",
                    height: "25px"
                })
            });
            featureLayer.renderer = pointSymbol;
            arcgisMap.add(featureLayer);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const loadMap = useCallback(async () => {
        try {
            if (!mapRef.current || !geoJson) {
                return;
            }
            const arcgisMap = new ArcGISMap({ basemap });
            const wgs84 = toWgs84(geoJson);

            new MapView({
                container: mapRef.current,
                map: arcgisMap,
                center: center(wgs84),
                zoom: zoom
            });

            renderGeoJsonFeatures(arcgisMap, wgs84, iconUrl);
            renderGeoJsonFeatures(arcgisMap, await getCurrentLocation(), '/assets/user-location.png' );
        } catch (e) {
            console.log(e);
        }

    }, [mapRef, basemap, zoom, geoJson, iconUrl, renderGeoJsonFeatures]);

    useEffect(() => {
        loadMap();
    }, [loadMap]);

    return (
        <div ref={mapRef} style={{ width, height, marginTop: "20px" }}></div>
    );
}