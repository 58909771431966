import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Image, Typography } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { SmartValueAttachment } from "../../types";

interface ImageSliderProps {
    list?: SmartValueAttachment[]
    imageHeight?: number
    imageWidth?: number
    enableSeeMoreAt?: number
}

enum DIRECTION {
    LEFT,
    RIGHT
}

function ImageSlider({ list, imageHeight = 350, imageWidth = 300, enableSeeMoreAt = 45 }: ImageSliderProps) {

    const [expandedTexts, setExpandedTexts] = useState<boolean[]>(new Array(list?.length).fill(false));
    const [showButtons, setShowButtons] = useState<boolean>(false);
    const [calculatedMinWidth, setCalculatedMinWidth] = useState<number>(imageWidth);
    const sliderRef = useRef<HTMLDivElement>(null);

    const toggleTextExpansion = (index: number) => {
        setExpandedTexts(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const scrollSlider = (direction: DIRECTION.LEFT | DIRECTION.RIGHT) => {
        if (sliderRef.current) {
            const scrollAmount = direction === DIRECTION.LEFT ? -300 : 300;
            sliderRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    const SliderButton = ({ direction }: { direction: DIRECTION.LEFT | DIRECTION.RIGHT }) => {
        return (
            <button
                className={`scroll-button ${direction === DIRECTION.LEFT ? 'left' : 'right'}`}
                onClick={() => scrollSlider(direction)}>
                {direction === DIRECTION.LEFT ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
            </button>
        )
    }

    const checkOverflow = useCallback(() => {
        if (sliderRef.current) {
            const { scrollWidth, clientWidth } = sliderRef.current;
            setShowButtons(scrollWidth > clientWidth);
        }
    }, []);
    
    const calculateMinWidth = useCallback(() => {
        if (sliderRef.current &&  list && list.length > 0) {
            const numImages = list?.length || 1;
            const maxAvailableWidth = (sliderRef.current.clientWidth / numImages) - 10;
            setCalculatedMinWidth(maxAvailableWidth < imageWidth ? imageWidth : maxAvailableWidth);
        }
    }, [list, imageWidth]);

    useEffect(() => {
        const handleResize = () => {
            checkOverflow();
            calculateMinWidth();
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [list, checkOverflow, calculateMinWidth]);

    return list && list.length > 0 ? (
        <div className="image-slider-wrapper">
            {showButtons && <SliderButton direction={DIRECTION.LEFT} />}
            <Image.PreviewGroup>
                <div className='image-slider' ref={sliderRef}>
                    {list.map((item, index) => (
                        <div key={index} className='wrapper' style={{
                            minWidth: calculatedMinWidth,
                            height: imageHeight
                        }}>
                            {item.title && <div className='overlay'>
                                <Typography>
                                    {expandedTexts[index] ? item.title : `${item.title.substring(0, enableSeeMoreAt)} `}
                                    {item.title.length > enableSeeMoreAt && (
                                        <span
                                            onClick={() => toggleTextExpansion(index)}
                                            className="text-expand-button"
                                            style={{ color: 'blue', cursor: 'pointer' }}
                                        >
                                            {expandedTexts[index] ? <p className="show-less">show less</p> : " see more"}
                                        </span>
                                    )}
                                </Typography>
                            </div>}
                            <Image src={item.attachment} alt={`img-${index}`} />
                        </div>
                    ))}
                </div>
            </Image.PreviewGroup>
            {showButtons && <SliderButton direction={DIRECTION.RIGHT} />}
        </div>
    ) : <></>
}

export default ImageSlider