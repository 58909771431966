import { Dispatch, ReactElement, ReactNode, Reducer, createContext, useReducer } from "react";
import { User } from "../types";

const ACTIONS = {
    USER_SIGNED_IN: 'user-signed-in',
    QR_DATA_FOUND: 'qr-data-found',
    APP_INSTALL_REQUESTED: 'app-install-requested',
    SHOW_SPLASH: 'splash-view'
};

type StateType = {
    authUser: User | null | undefined,
    qrData: any,
    appInstallRequested: boolean,
    showSplash:boolean
};

export type ActionType = {
    type: string;
    payload: any;
};

export type DispatchType = Dispatch<ActionType>;


type ReduceType = Reducer<StateType, ActionType>;

type ContextType = {
    authState: StateType,
    dispatch: DispatchType
};

type PropsType = {
    children: ReactNode
};

const initialState: StateType = {
    authUser: undefined,
    qrData: undefined,
    appInstallRequested: false,
    showSplash: true
};

const authContext = createContext<ContextType>({ authState: initialState, dispatch: () => null });
const { Provider } = authContext;

const AuthProvider = ({ children }: PropsType): ReactElement => {
    const [authState, dispatch] = useReducer<ReduceType>((state: StateType, action: ActionType): StateType => {
        const { type, payload } = action;
        switch (type) {
            case ACTIONS.USER_SIGNED_IN:
                return { ...state, authUser: payload };
            case ACTIONS.QR_DATA_FOUND:
                return { ...state, qrData: payload };
            case ACTIONS.APP_INSTALL_REQUESTED:
                return { ...state, appInstallRequested: payload }
            case ACTIONS.SHOW_SPLASH:
                return { ...state, showSplash: payload }    
            default:
                return state;
        }
    }, initialState);


    return <Provider value={{ authState, dispatch }}>{children}</Provider>;
};

export { authContext, AuthProvider, ACTIONS };
