import { Button, Flex, Tooltip, Typography, message, Image } from 'antd'
import { GeoJsonMapView, ImageSlider, Loading, ViewLayout } from '../../../components'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ACTIONS, authContext } from '../../../context/auth'
import { getSmartValue } from '../actions'
import { SmartValueAttachment, SmartValueDetails } from '../../../types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { generateId, getFileFriendlyName, groupByExt, saveLoggedUser } from '../../../utils'
import DOMPurify from 'dompurify'
import { DownloadOutlined } from '@ant-design/icons'

function SmartValueDetailView() {
    const { authState, dispatch } = useContext(authContext)
    const { qrData, authUser } = authState
    const [isLoading, setLoading] = useState<boolean>(true)
    const [smartValue, setSmartValue] = useState<SmartValueDetails>()
    const [attachments, setAttachments] = useState<{ images: SmartValueAttachment[], other: SmartValueAttachment[] }>();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const loadSmartValue = useCallback(async () => {
        try {
            setLoading(true);
            if (!qrData?.orgId || !qrData.smartValueId || !authUser?.userId || !executeRecaptcha) {
                return;
            }
            const reCaptchaToken = await executeRecaptcha('load_smart_value');
            const value = await getSmartValue(qrData.orgId, authUser.userId, qrData.smartValueId, reCaptchaToken);
            setSmartValue(value);
        } catch (e) {
            console.log(e);
            message.error('Error in getting SMART Value details, Please retry.');
            // go back to scan.
            dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: undefined });
        } finally {
            setLoading(false)
        }
    }, [qrData, authUser, dispatch, executeRecaptcha]);

    useEffect(() => {
        if (!authUser?.userId) {
            saveLoggedUser({ userId: generateId() }, dispatch);
        }
    }, [authUser, dispatch]);

    useEffect(() => {
        loadSmartValue()
    }, [loadSmartValue, qrData]);

    const onRescanRequest = () => dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: undefined });

    useEffect(() => {
        setAttachments(groupByExt(smartValue?.attachments))
    }, [smartValue])

    const otherAttachments = attachments?.other && attachments?.other.length > 0 ? (
        <div className="full-width">
            <Typography.Title className='margin-bottom' level={5}>Attachments</Typography.Title>
            <div>
                {attachments?.other.map((file, index) => (
                    <Flex gap='middle' key={`att-${index}`} className="image-attachment">
                        <img className='attachment-img' src={'/icons/file.png'} alt='file-thumbnail' />
                        <Flex vertical align='start'>
                            <Typography.Text className='margin-bottom'>{getFileFriendlyName(file.attachment)}</Typography.Text>
                            <Button className='border-radius margin-top' download={true} href={file.attachment} icon={<DownloadOutlined />}>Download</Button>
                        </Flex>
                    </Flex>
                )
                )}
            </div>
        </div>
    ) : <></>

    const description = smartValue?.description ? (
        <div className="full-width margin-bottom">
            <Typography.Title level={5}>Description</Typography.Title>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(smartValue?.description) }} />
        </div>
    ) : <></>

    const title = smartValue?.type ? (
        <div className="flex title-container">
            <img src={`/smart-value-type-icons/logo/${smartValue.type}.svg`} alt={smartValue.type} width={25} height={25} />
            <Tooltip placement='bottom' title={smartValue?.title}>
                <span className="smart-value-title">{smartValue?.title}</span>
            </Tooltip>
        </div>
    ) : <span className="smart-value-title">{smartValue?.title}</span>

    if (isLoading) return <Loading />

    return (
        <>
            <ViewLayout title={title} className='content-height'>
                <div className="pad-all full-width">
                    <ImageSlider imageWidth={250} imageHeight={325} list={attachments?.images} />
                    {smartValue?.locations && <GeoJsonMapView
                        geoJson={smartValue.locations}
                        iconUrl={`/smart-value-type-icons/map/${smartValue.type}.png`}
                        height='200px'
                    />}
                    {description}
                    {otherAttachments}
                    <Button className='full-width button-radius margin-bottom margin-top' htmlType="button" size="large" onClick={onRescanRequest}>
                        Re-Scan the QR code
                    </Button>
                </div>
            </ViewLayout>
        </>
    )
}

export default SmartValueDetailView


