import { Drawer, DrawerProps } from 'antd'
import HtmlRender from '../HtmlRender/HtmlRender'

interface DrawerRenderProps {
  title: string
  open: boolean
  contentUrl: string
  placement?: DrawerProps['placement']
  onClose?: () => void
}

function DrawerRender({ title, open, contentUrl, placement, onClose }: DrawerRenderProps) {
  
  return (
    <>
    <Drawer
        title={title}
        placement={placement}
        closable={true}
        onClose={onClose}
        open={open}
        key={placement}
        size='large'
      >
      { contentUrl && <HtmlRender url={contentUrl} /> }
      </Drawer>
    </>
  )
}

export default DrawerRender