import { CameraOutlined, DeleteOutlined, PictureTwoTone } from '@ant-design/icons';
import { Button, Card, Drawer, Space, message } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { generateFileName, Base64File } from '../../utils';
import { CameraFacingMode } from '../../types';

type PropsType = {
    value?: Base64File[],
    title?: string,
    onChange?: (value: Base64File[]) => void,
    max?: number,
    facingMode?: CameraFacingMode
}

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
};

type CameraListItemPropsType = {
    title: string,
    onDelete: (key: string) => void
}
function CameraPhotoListItem({ title, onDelete }: CameraListItemPropsType) {

    return (
        <Card className='camera-photo-list-card' key={title}>
            <Space className='camera-photo-list-card-body'>
                <PictureTwoTone className='camera-photo-logo' />
                <p className='camera-photo-name' title={title}>{title}</p>
                <Button onClick={() => onDelete(title)} size='large' className='camera-photo-delete' type='text' icon={<DeleteOutlined />} />
            </Space>
        </Card>
    );
}

export default function CameraFormItem({ value, onChange, max = 1, facingMode = "environment", title }: PropsType) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const webcamRef = useRef<Webcam>(null);
    const [photos, setPhotos] = useState<Base64File[]>([]);

    useEffect(() => {
        setPhotos(value ?? []);
    }, [value]);

    const triggerChange = useCallback((photos: Base64File[]) => {
        onChange?.(photos);
    }, [onChange]);

    const onCloseModal = () => setShowModal(false);

    const capture = useCallback(async () => {
        try {
            if (!webcamRef.current || photos.length >= max) {
                return;
            }
            const imageSrc = webcamRef.current.getScreenshot();
            if (!imageSrc) {
                throw new Error('Empty image src');
            }
            const file = new Base64File(imageSrc, generateFileName('jpg', `${title}-${photos.length + 1}`));
            const _photos = [...photos, file]
            setPhotos(_photos);
            triggerChange(_photos);
            onCloseModal();
        } catch (e) {
            console.log(e);
            message.error('Error in capturing photo. Please retry');
        }
    }, [webcamRef, photos, triggerChange, max, title]);


    const drawerContent = showModal ? (
        <Space.Compact direction='vertical' className='full-width' >
            <Webcam
                audio={false}
                width={'100%'}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ ...videoConstraints, facingMode }}
            />
            <Button type='primary' size='large' className='margin-top' onClick={capture}>Capture</Button>
            <Button className='margin-top' size='large' onClick={onCloseModal}>Close</Button>
        </Space.Compact>
    ) : <div></div>;

    const onPhotoDelete = (name: string) => {
        const _photos = photos.slice();
        const index = _photos.findIndex((photo) => photo.getName() === name);
        if (index > -1) {
            _photos.splice(index, 1);
            setPhotos(_photos);
            triggerChange(_photos);
        }
    }

    return (
        <>
            <Drawer
                title="Capture photo"
                placement='right'
                size='large'
                open={showModal}
                onClose={onCloseModal}
            >
                {drawerContent}
            </Drawer>
            <Button className='button-radius' size='large' icon={<CameraOutlined />} onClick={() => setShowModal(true)}>Capture Photo</Button>
            {photos.map((file: Base64File) => <CameraPhotoListItem key={file.getName()} title={file.getName()} onDelete={onPhotoDelete} />)}
        </>
    );
}