import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, List, message } from 'antd';
import { PageableResponse, Submission } from '../../../types';
import { Loading } from '../../../components';
import { getInbox } from '../actions';
import { useNavigate } from 'react-router-dom';
import { ROUTES, SUBSMISSION_TYPES } from '../../../constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type PropsType = {
    status: string
    searchPhrase?: string,
    refreshTrigger?: boolean
}

type SearchableSubmission = {
    searchText: string,
    item: Submission
}

export default function InboxTabBody({ status, searchPhrase, refreshTrigger }: PropsType) {
    const [inbox, setInbox] = useState<PageableResponse<Submission>>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [filteredInbox, setFilteredInbox] = useState<Submission[]>([]);
    const [searchInbox, setSearchInbox] = useState<SearchableSubmission[]>([]);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const loadInbox = useCallback(async (lastEvaluatedKey?: string) => {
        try {
            if(!executeRecaptcha) {
                return;
            }
            setLoading(true);
            const reCaptchaToken = await executeRecaptcha('load_inbox');
            const result = await getInbox(reCaptchaToken, status, lastEvaluatedKey);
            result.items = result.items.sort((a: any, b:any) => {
                const lastValueA = a.submissionId?.split('_').pop();
                const lastValueB = b.submissionId?.split('_').pop();
                return lastValueB?.localeCompare(lastValueA);
            });
            setInbox(res => ({
                items: [...(res?.items ?? []), ...(result.items ?? [])],
                lastEvaluatedKey: result.lastEvaluatedKey
            }));
            setSearchInbox(searchInbox => searchInbox.concat(result.items.map((item: Submission) => ({
                searchText: `${item.formTitle} ${item.completedTime}`.toLowerCase(),
                item: item
            }))));
        } catch (e) {
            message.error('Error in loading inbox');
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [status, executeRecaptcha]);

    useEffect(() => {
        setInbox(undefined);
        setSearchInbox([]);
        loadInbox();
    }, [loadInbox, refreshTrigger]);

    useEffect(() => {
        if (!inbox?.items) {
            return;
        }
        if (searchPhrase) {
            const filteredInbox = searchInbox.filter((inbox: any) => inbox.searchText?.includes(searchPhrase.toLowerCase())).map((inbox: any) => inbox.item);
            setFilteredInbox(filteredInbox);
        } else {
            setFilteredInbox(inbox?.items);
        }
    }, [searchPhrase, inbox, searchInbox]);

    const getCheckOutTimeString = (item: Submission) => {
        let phrase = '';
        let dt: Date | undefined;
        if (item.completedTime) {
            phrase = 'Completed at';
            dt = new Date(item.completedTime);
        } else if (item.checkoutTime) {
            phrase = 'Checked-out at';
            dt = new Date(item?.checkoutTime!);
        } else if (item.startedTime) {
            phrase = 'Started at';
            dt = new Date(item?.startedTime);
        } else {
            return phrase;
        }
        return `${phrase} ${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`
    }

    const loadMore =
        inbox?.lastEvaluatedKey ? (
            <div className='content-centered margin-top pad-bottom'>
                <Button className='border-radius' onClick={() => loadInbox(inbox.lastEvaluatedKey)} >load more</Button>
            </div>
        ) : null;

    const navigate = useNavigate();
    const onItemClick = (id: string | undefined, submissionType: string | undefined) => {
        if(!id || !submissionType|| submissionType === SUBSMISSION_TYPES.SMART_VALUE) {
            return;
        }
         navigate(`${ROUTES.INBOX}/${id}`);
    }

    const getIconUrl = (item?: Submission) => {
        switch (item?.submissionType) {
            case 'SmartValue':
                return `/smart-value-type-icons/logo/${item?.smartValueType ? item?.smartValueType : 'defualt'}.svg`;
            default:
                return `/assets/${item?.submissionType}.png`
        };
    };

    return (
        <List
            itemLayout="horizontal"
            className='full-width'
            dataSource={filteredInbox}
            rowKey={"submissionId"}
            loadMore={loadMore}
            loading={{
                spinning: isLoading,
                indicator: <Loading />
            }}

            renderItem={(item) => (
                <List.Item onClick={() => onItemClick(item.submissionId, item.submissionType)}>
                    <List.Item.Meta
                        className="inbox-item"
                        avatar={<Avatar src={getIconUrl(item)} />}
                        title={item.sessionFormName ?? item.formTitle}
                        description={getCheckOutTimeString(item)}
                    />
                </List.Item>
            )}
        />
    )
}