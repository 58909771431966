import { PageableResponse, Submission, SubmissionDetails } from "../../types";
import { fetchJson, getApiFullUrl, download, getLoggedUser } from "../../utils";


export const getInbox = async (reCaptchaToken: string, status?: string, startAt?: string): Promise<PageableResponse<Submission>> => {
    const user = getLoggedUser();
    if (!user?.userId) {
        return { items: [] };
    }
    const results = await fetchJson<PageableResponse<Submission>>(getApiFullUrl(`/user-inbox?&status=${status}&limit=20${startAt ? '&exclusiveStartKey='.concat(startAt) : ''}`),
        reCaptchaToken,
        { Authorization: user.userId });
    return results;
}

export const getSubmissionDetails = async (submissionId: string, reCaptchaToken: string): Promise<SubmissionDetails> => {
    const user = getLoggedUser();
    if (!user?.userId) {
        throw new Error('User not logged');
    }
    return await fetchJson<SubmissionDetails>(getApiFullUrl(`/submission/${user.userId}/${submissionId}`), reCaptchaToken);
}

export const downloadFile = async (url: string, fileName: string, reCaptchaToken: string) => {
    const getUrl = await fetchJson<{ url: string }>(getApiFullUrl(`/attachment-url?path=${url}`), reCaptchaToken);
    download(getUrl.url, fileName);
}

