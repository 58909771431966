import React, { useCallback, useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainView, SplashView } from './views';
import { AppInstallRequester } from './components';
import { ACTIONS, authContext } from './context/auth';
import { getLoggedUser } from './utils';
import packageJson from '../package.json';
import { getAppManifest } from './actions';
import { checkAppUpdate } from './configs/configs';
import { message } from 'antd';

function App() {
  const { dispatch, authState } = useContext(authContext);
  const { showSplash } = authState;

  const updateApp = useCallback(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }
    message.loading('ALDO is updating...', 0);
    navigator.serviceWorker.getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
        window.location.reload();
      }).catch(function (err) {
        console.log('Service Worker registration failed: ', err);
      }).finally(() => {
        message.destroy();
      });
  }, []);

  useEffect(() => {
    const removeSplash = () => {
      setTimeout(() => dispatch({ type: ACTIONS.SHOW_SPLASH, payload: false }), 3000);
    }
    if (!checkAppUpdate) {
      removeSplash();
      return;
    }

    getAppManifest().then(({ version }) => {
      if (version !== packageJson.version) {
        updateApp();
      }
    }).catch(() => { console.log('Error fetching manifest') })
      .finally(() => {
        removeSplash();
      });
  }, [updateApp, dispatch]);

  useEffect(() => {
    dispatch({ type: ACTIONS.USER_SIGNED_IN, payload: getLoggedUser() });
  }, [dispatch]);

  return (
    <>
      <AppInstallRequester />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={showSplash ? <SplashView /> : <MainView />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
