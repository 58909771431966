import { Flex, Layout, Typography } from 'antd';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { Artifact } from '../Artifact';
import { Home } from '../Home';
import { Inbox, ViewDetails } from '../Inbox';
import { Settings } from '../Settings';
import { About } from '../About';
import AppHeader from './components/AppHeader';
const { Content } = Layout;

const checkConnection = () => {
    const noInternetBanner = (
        <div className='content-centered'>
            <Flex justify='center' className='theme-max-width full-width no-internet-banner'>
                <Typography.Text className='no-internet-msg'>There is no internet connection.</Typography.Text>
            </Flex>
        </div>
    );

    return navigator.onLine ? null : noInternetBanner;
}

export default function MainView() {
    return (
        <Layout className='full-width full-height white-bg relative'>
            <AppHeader />
            {checkConnection()}
            <Content className='content-height relative'>
                <Routes>
                    <Route path={ROUTES.ARTIFACT} element={<Artifact />} />
                    <Route path={ROUTES.HOME} element={<Home />} />
                    <Route path={ROUTES.ABOUT} element={<About />} />
                    <Route path={ROUTES.INBOX} element={<Inbox />} />
                    <Route path={ROUTES.SETTINGS} element={<Settings />} />
                    <Route path={`${ROUTES.INBOX}/:submissionId`} element={<ViewDetails />} />
                    <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
                </Routes>
            </Content>
        </Layout>
    )
}