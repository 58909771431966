import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import ReactSignatureCanvas from 'react-signature-canvas';
import { DeleteOutlined } from '@ant-design/icons';
import { generateFileName, Base64File } from '../../utils';

const SignaturePad = ReactSignatureCanvas;

type PropsType = {
    value?: Base64File[],
    title?: string,
    onChange?: (value: Base64File[]) => void
}

type CanvasDimension = {
    width: number,
    height: number
}

export default function SignatureFormItem({ value, title, onChange }: PropsType) {
    const signatureRef = useRef<ReactSignatureCanvas>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [signatureFiles, setSignatureFiles] = useState<Base64File[]>([]);
    const [canvasDimension, setCanvasDimension] = useState<CanvasDimension>({ width: 300, height: 225 });

    useEffect(() => {
        function resizeCanvas() {
            let wrapperWidth = wrapperRef.current?.offsetWidth ?? 316;
            wrapperWidth -= 16;
            setCanvasDimension({ width: wrapperWidth, height: wrapperWidth * 0.75 });
        }
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);
        return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    useEffect(() => {
        async function setSignatureInitialData() {
            const formValues = value ?? [];
            if (signatureRef.current && signatureFiles.length === 0 && formValues.length > 0) {
                const file = formValues[0];
                signatureRef.current?.fromDataURL(file.getBase64(), { width: file.getWidth(), height: file.getHeight() });
                setSignatureFiles(formValues);
            }
        };
        const timeout = setTimeout(setSignatureInitialData, 1000);
        return () => clearTimeout(timeout);
    }, [value, signatureFiles]);

    const triggerChange = useCallback((signatures: Base64File[]) => {
        setSignatureFiles(signatures);
        onChange?.(signatures);
    }, [onChange]);

    const clearSignature = () => {
        signatureRef.current?.clear();
        triggerChange([]);
    };

    const handleDrawingEnd = async () => {
        const signatureData = signatureRef.current?.toDataURL();
        if (!signatureData) {
            return;
        }
        const file = new Base64File(signatureData, generateFileName('png', title), canvasDimension.width, canvasDimension.height);
        triggerChange([file]);
    };

    const handleDrawingBegin = () => {
        const activeElement = document.activeElement as HTMLElement | null;
        if (activeElement && activeElement.blur) {
            activeElement.blur();
        }
    };

    return (
        <div className='signature-pad' ref={wrapperRef}>
            <SignaturePad
                ref={signatureRef}
                onEnd={handleDrawingEnd}
                onBegin={handleDrawingBegin}
                canvasProps={{ width: canvasDimension.width, height: canvasDimension.height }}
                penColor='blue' />
            <div className='signature-pad-button'>
                <Button icon={<DeleteOutlined />} className='border-radius' type='text' size='large' onClick={clearSignature}>Clear</Button>
            </div>
        </div>
    );
}