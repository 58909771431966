import { RedoOutlined } from '@ant-design/icons';
import { Divider, Space, Flex, Tabs, TabsProps, Typography, Input, Button } from 'antd';
import React, { ReactNode } from 'react';

type PropsType = {
    title?: ReactNode,
    tabs: TabsProps['items'],
    defaultActiveTabKey: string
    activeKey?: TabsProps['activeKey']
    onTabChange?: TabsProps['onChange']
    showSearch?: boolean,
    searchPlaceHolder?: string,
    onSearch?: (text: string) => void | undefined,
    onRefreshButtonClick?: () => void | undefined
}

function ScrolledTabBody({ children, hasTitle }: { children: ReactNode, hasTitle: boolean }) {
    const scrollViewClass = !hasTitle ? 'no-title' : '';
    return (
        <Space.Compact className={`full-width tabbed scroll-view pad-hori ${scrollViewClass}`}>
            {children}
        </Space.Compact>
    )
}

export default function TabViewLayout({ title, tabs, defaultActiveTabKey, activeKey, onTabChange, showSearch = true, searchPlaceHolder, onSearch, onRefreshButtonClick }: PropsType) {

    const tabItems = tabs?.map((tab) => (
        {
            ...tab,
            children: (<ScrolledTabBody hasTitle={!!title}>{tab.children}</ScrolledTabBody>)
        }
    ));

    const searchElement = showSearch ? (
        <Flex gap={4} className='search-width'>
            <Input.Search className='' size='large' onChange={(e) => onSearch!(e.target.value)} placeholder={searchPlaceHolder} />
            {onRefreshButtonClick && <Button type='default' className='refresh-btn border-radius no-margin' icon={<RedoOutlined />} onClick={onRefreshButtonClick} />}
        </Flex>
    ) : <></>;

    const titleContent = title ? (
        <>
            <Flex justify='space-between' className='inbox-title-bar full-width margin-bottom pad-all'>
                <Typography.Title level={4} className='no-margin text-overflow-ellipse view-layout-title'>{title}</Typography.Title>
                {searchElement}
            </Flex>
            <Divider className='no-margin pad-hori' />
        </>
    ) : <></>;

    return (
        <div className='full-width content-centered full-height'>
            <div className='theme-max-width full-width box-style full-height'>
                <Space.Compact direction='vertical' className='full-width full-height padding-top'>
                    {titleContent}
                    <Tabs animated className='tab-view-layout full-width' defaultActiveKey={defaultActiveTabKey} items={tabItems} activeKey={activeKey} onChange={onTabChange} />
                </Space.Compact>
            </div>
        </div>
    );
}