import { Progress, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Loading, ViewLayout } from '../../../components';

export type FileUploadProgressItem = {
    name: string,
    progress: number
};

export type PropsType = {
    progress: number
}

export default function FormSubmissionProgress({ progress }: PropsType) {

    const [uploadProgress, setUploadProgress] = useState<number>(0)

    useEffect(() => {
        setUploadProgress(progress);
    }, [progress])

    return (
        <ViewLayout
            title={<Space><Loading /> Submitting...</Space>}
            className='form-content-height'
        >
            <div className="full-width progress-wheel" >
                <Progress size={200} type="circle" percent={uploadProgress} />
            </div>
        </ViewLayout>
    );
}