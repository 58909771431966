import React, { useCallback, useEffect, useState } from 'react';
import { fetchJson, fetchText, getApiFullUrl } from '../../utils';
import DOMPurify from 'dompurify';
import Loading from '../Loading/Loading';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type PropsType = {
    url: string
}

export default function HtmlRender({ url }: PropsType) {
    const [content, setContent] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const loadHtmlContent = useCallback(async (): Promise<void> => {
        try {
            if(!executeRecaptcha) {
                return;
            }
            setLoading(true);
            const reCaptchaToken = await executeRecaptcha('load_html_content');
            const data = await fetchJson<{ url: string }>(getApiFullUrl(`/schema-download-url?path=${url}`), reCaptchaToken);
            const content = await fetchText(data.url);
            setContent(DOMPurify.sanitize(content));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [url, executeRecaptcha]);

    useEffect(() => {
        loadHtmlContent();
    }, [loadHtmlContent]);

    if (isLoading) return <Loading />;

    return (
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
    );
}