import React, { useState } from 'react';
import { Field, getRules } from './DynamicFormItems';
import { Button, Form, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getFileExtension } from '../../utils';

type PropsType = {
    field: Field,
    keyId: string
}

export default function UploadFormItem({ field, keyId }: PropsType) {
    const { name, label, accept, max = 1 } = field;
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const addDotToExtensions = (str: string) => ('.' + str.replace(/,\s?/g, ', .'))

    const isAcceptedFile = (file: UploadFile) => {
        const ext = getFileExtension(file.name ?? '');
        if (!ext) {
            return false;
        }
        return accept?.toLowerCase().split(',').includes(ext.toLowerCase());
    }

    const uploadProps: UploadProps = {
        beforeUpload: (file: UploadFile) => {
            if (isAcceptedFile(file)) {
                setFileList([...fileList, file]);
            }
            return false;
        },
        fileList,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        accept: addDotToExtensions(accept ?? ''),
        maxCount: max,
        listType: "picture",
    }

    return (<Form.Item
        key={keyId}
        name={name}
        label={label}
        valuePropName="fileList"
        getValueFromEvent={(e) => fileList}
        rules={getRules(field)}
    >
        <Upload className='uploaditems'{...uploadProps} >
            <Button className='button-radius' size='large' icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
    </Form.Item>
    );
}