import { convertBase64ToFile } from "./commons";

export default class Base64File {

    private base64: string;
    private width?: number;
    private height?: number;
    private name: string

    constructor(base64: string, name: string, width?: number, height?: number) {
        this.base64 = base64;
        this.name = name;
        this.width = width;
        this.height = height;
    }

    public getFile = async (): Promise<File> => {
        return convertBase64ToFile(this.base64, this.name)
    };

    public getName = (): string => this.name;
    public getWidth = (): number | undefined => this.width;
    public getHeight = (): number | undefined => this.height;
    public getBase64 = (): string => this.base64;
}

export const getFileName = (file: File | Base64File): string => {
    return (file as Base64File).getName ? (file as Base64File).getName() : (file as File).name
}

export const getOriginalFile = async (file: File | Base64File): Promise<File> => {
    return (file as any).getFile ? await (file as Base64File).getFile() as File : file as File
}