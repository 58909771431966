import React, { useContext, useEffect, useState } from 'react';
import { Space, Flex, Col, Typography } from 'antd';
import { ACTIONS, authContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { toDayDateMonthFormat, toUpperCaseTimeString } from '../../utils/datetime';
import dayjs from 'dayjs';

export default function Home() {
    const [currentTime, setCurrentTime] = useState(dayjs());
    const { dispatch } = useContext(authContext);
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(dayjs());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const onScanQrCodeClick = () => {
        dispatch({ type: ACTIONS.QR_DATA_FOUND, payload: undefined })
        navigate(ROUTES.ARTIFACT);
    }

    return (
        <div className='full-width content-centered full-height relative'>
            <img className='background-image' src="/assets/home.png" alt="background" />
            <div className='theme-max-width full-width full-height'>
                <Space.Compact direction='vertical' className='full-width full-height'>
                    <Flex className='widget border-radius pad-all content-centered flex-column'>
                            <Col >
                                <Typography.Title className='widget-text time' level={1}>{toUpperCaseTimeString(currentTime)}</Typography.Title>
                            </Col>
                            <Col >
                                <Typography.Title className='widget-text date' level={5}>{toDayDateMonthFormat(currentTime)}</Typography.Title>
                            </Col>
                        <Col className='welcome-sesion'>
                            <Typography.Text className='welcome-text'>Welcome to <b>ALDO</b></Typography.Text>
                        </Col>
                    </Flex>


                    <Flex className='btn-section content-centered margin-bottom'>
                        <Col className='btn-container content-centered flex-column' onClick={onScanQrCodeClick}>
                            <img width={32} height={32} src='/icons/qr-code.png' alt='' />
                            <Typography.Text className='btn-text'>Scan QR Code</Typography.Text>
                        </Col>
                        <Col className='btn-container content-centered flex-column' onClick={() => navigate(ROUTES.INBOX)}>
                            <img width={32} height={32} src='/icons/inbox.png' alt='' />
                            <Typography.Text className='btn-text'>Inbox</Typography.Text>
                        </Col>
                    </Flex>
                </Space.Compact>
            </div>
        </div>
    );
}